import React from 'react';
import PropTypes from 'prop-types';

import 'assets/stylesheets/application.scss';

import Footer from './Footer';

const Layout = ({ children, className, path }) => {
  return (
    <div className={`wrapper ${className || ''}`}>
      <main>{children}</main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
  className: PropTypes.string
};

export default Layout;
