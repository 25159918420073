import React from 'react';

import Container from 'components/Container';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container type="full" color="gray-dark">
          <p className="align-center">
            © {new Date().getFullYear()},{' '}
            <a href="https://www.element84.com/">Element 84</a>
          </p>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
