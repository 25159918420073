import React from 'react';
import PropTypes from 'prop-types';

import ClassName from 'models/classname';

import Container from 'components/Container';

const Jumbotron = ({ children, align = 'center' }) => {
  const className = new ClassName('jumbotron');

  className.add(`jumbotron-align-${align}`);

  return (
    <Container className={className.string}>
      <div className={className.child('content')}>{children}</div>
    </Container>
  );
};

Jumbotron.propTypes = {
  children: PropTypes.node,
  align: PropTypes.string
};

export default Jumbotron;
